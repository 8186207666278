import './index.scss';

function NotFound() {
  return (
    <div className="NotFound">
      Error 404!
    </div>
  );
}

export default NotFound;